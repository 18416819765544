/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Box } from "@mui/material";

function CenteredBlogCard({ image, title }) {
  return (
    <Card
  sx={{
    //backgroundColor: '#181818',
    //boxShadow: 'none',
    width: '100%',
    height: '145px',
    position: 'relative',
  }}
>

  <MKBox
    position="absolute"
    top={0}
    left={0}
    right={0}
    bottom={0}
    borderRadius="lg"
    sx={{ overflow: 'hidden' }}
  >
    <img
      src={image}
      alt={title}
      style={{
        width: '105%',
        height: '105%',
        objectFit: 'cover',
        cursor: 'grab',
      }}
    />
    
  </MKBox>
  <MKBox
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      sx={{
        background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1))',
        borderRadius:"8px",
        display: 'flex',       // Uso de Flexbox
        alignItems: 'center',  // Centrado vertical
        justifyContent: 'center', // Centrado horizontal
        textAlign: 'center',   // Centrado del texto
      }}
    >
      <MKBox sx={{
        bottom: '0',     
      position: 'absolute',
       alignItems: 'center',
       textAlign: 'center'

}} p={1} mt={0} textAlign="center">
      <h6 style={{ color: '#ffffff', fontWeight: 400 }}>{title.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}</h6>
    </MKBox> 
    </MKBox>
</Card>
);
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CenteredBlogCard;


/*<MKBox p={1} mt={-1} textAlign="center">
      <h6 style={{ color: '#ffffff', fontWeight: 400 }}>{title.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}</h6>
    </MKBox> */