import * as React from "react";
import { Suspense, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import { Link as MuiLink } from "@mui/material";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import bgImage from "assets/images/bg-presentation-renault-x.jpg";
import bgRenault from "assets/images/bg-renault.webp";
import { Helmet } from "react-helmet";

import Dudando from "assets/images/Dudando.webp";
import PadreHijo from "assets/images/Padre HIjo.webp";
import NewCar from "assets/images/New Car.webp";

import Historial from "assets/images/Historia.webp";
import Kilometraje from "assets/images/Kilometraje.webp";
import Mantenimiento from "assets/images/Mantenimiento.webp";

import Accidente from "assets/images/Accidente.webp";
import Factura from "assets/images/Factura.webp";
import Tirada from "assets/images/Tirada.webp";

import Averia from "assets/images/Averias.webp";
import Reparaciones from "assets/images/Seguro.webp";
import Garantia from "assets/images/Garantia.webp";

import CenteredFooter from "examples/Footers/CenteredFooter";
import YouTubeCard from "pages/Presentation/sections/YouTube";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function KodeLegal() {
  const navigate = useNavigate();
  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    //console.log(nuevoValor);
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };

  return (
    <>
      <Helmet>
        <title>Kode Cars Market - Aviso Legal</title>
      </Helmet>
      <Box sx={{ backgroundColor: "#181818", minHeight: "100vh" }}>
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          {
            <ResponsiveAppBar
              reservationDrawer={reservationDrawer}
              onCambio={manejarCambio}
            />
          }
        </Box>
        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), #181818), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`,
                  },
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ maxWidth: "100%" }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1,
                        fontSize: {
                          xs: "2.5rem",
                          sm: "3rem",
                          md: "4rem",
                          lg: "5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      Aviso Legal
                    </MKTypography>
                    <MKTypography
                      color="white"
                      variant="h3"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1.5,
                        fontSize: {
                          xs: "1.5rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2.5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    ></MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>

        {/* Cookie Policy Section */}
        <Container
          sx={{
            mt: {
              xs: -2,
              sm: -2,
              md: -2,
              lg: -14,
            },
            mb: 8,
            backgroundColor: "white",
            color: "#ffffff",
          }}
        >
          <p>
            LLa información proporcionada en kodecarsmarket.com es
            exclusivamente de carácter informativo. No vendemos vehículos
            directamente; actuamos como intermediarios en la gestión de ventas
            entre los clientes y el concesionario Renault. Todos los precios y
            la disponibilidad están sujetos a cambios sin previo aviso y deben
            confirmarse con el concesionario Renault antes de formalizar
            cualquier transacción de compra.
          </p>

          <p>
            El contenido de este sitio web no constituye una oferta legalmente
            vinculante. Cualquier compra de vehículos Renault se realizará
            directamente con el concesionario Renault, bajo sus propios términos
            y condiciones, siguiendo las directrices de la marca Renault y, en
            su caso, las condiciones financieras de su filial MOBILIZE.
          </p>
        </Container>
        <MKBox bgColor="info" mt={4}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              {<CenteredFooter pt={6} px={1} />}
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default KodeLegal;
