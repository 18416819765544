import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';


const Cookiespop = () => {
   
    const [showConsent, setShowConsent] = useState(true);
    const [thirdPartyCookiesAccepted, setThirdPartyCookiesAccepted] = useState(false);

    useEffect(() => {
        const userConsent = Cookies.get('cookie_consent') || localStorage.getItem('cookie_consent');
        const thirdPartyConsent = Cookies.get('thirdPartyCookiesAccepted') || localStorage.getItem('thirdPartyCookiesAccepted');

        if (!userConsent) {
            setShowConsent(false);
        }

        if (thirdPartyConsent === 'true') {
            setThirdPartyCookiesAccepted(true);
            enableThirdPartyCookies();
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookie_consent', 'true', { expires: 365, sameSite: 'None', secure: true });
        Cookies.set('thirdPartyCookiesAccepted', 'true', { expires: 365, sameSite: 'None', secure: true });
        
        setShowConsent(false);
        setThirdPartyCookiesAccepted(true);
        enableThirdPartyCookies();
    };

    const handleReject = () => {
        Cookies.set('cookie_consent', 'true', { expires: 365, sameSite: 'None', secure: true });
        Cookies.set('thirdPartyCookiesAccepted', 'false', { expires: 365, sameSite: 'None', secure: true });

        setShowConsent(false);
        setThirdPartyCookiesAccepted(false);
        //console.log("Cookies de terceros rechazadas");
    };

    const enableThirdPartyCookies = () => {
        //console.log("Cookies de terceros habilitadas");
        // Aquí puedes incluir cualquier funcionalidad que dependa de cookies de terceros
        // Puede ser la carga de algún script o la activación de un iframe
    };

    if (!showConsent) {
        return null;
    }

    const styles = {
        container: {
          position: 'fixed',
          bottom: '20px',
          left: '10%',
          width: '80%',
          //backgroundColor: 'rgba(0, 0, 0, 0.99)',
          color: 'white',
          fontWeight: 400,
          textAlign: 'center',
          padding: '1.5rem',
          borderRadius: '10px',
          backgroundColor:  'info', 
          backdropFilter: 'saturate(200%) blur(30px)', 
          boxShadow:'0px 4px 20px rgba(0, 0, 0, 0.1)',
          zIndex: 2000,
        },
        button: {
          color: '#25D366',
          padding: '0.0rem 1rem',
          marginLeft: '1rem',
          cursor: 'pointer',
        },
        button2: {
            color: '#d32525',
            padding: '0.0rem 1rem',
            marginLeft: '1rem',
            cursor: 'pointer',
        },
    };

    return (
        
        <div style={styles.container} id="cookies" role="dialog" aria-labelledby="cookieConsentTitle" aria-describedby="cookieConsentDescription">
            <p style={{
                color: "#ffffff",
                fontWeight: 400,
                marginRight: "0px",
                fontSize: "1rem",}}
                 id="cookieConsentDescription">Usamos cookies para mejorar tu experiencia. Al usar nuestro sitio, consientes el uso de cookies. Si tu navegador está configurado para bloquear cookies de terceros, algunas funcionalidades pueden no estar disponibles o no funcionar debidamente.</p>
            <Button style={styles.button} onClick={handleAccept}>Aceptar</Button>
            <Button style={styles.button2} onClick={handleReject}>Rechazar</Button>
        </div>
    );
};

export default Cookiespop;