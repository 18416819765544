import React from 'react';
import { useInView } from 'react-intersection-observer';

const FadeInSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // La animación se dispara solo una vez
    threshold: 0.1 // El umbral de visibilidad (10%)
  });

  return (
    <div
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(80px)',
        transition: 'opacity 1.4s ease-out, transform 1.4s ease-out'
      }}
    >
      {children}
    </div>
  );
};

export default FadeInSection;
