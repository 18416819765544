// App.js
import React from 'react';
import Slider from "react-slick";
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

// Datos de ejemplo para las tarjetas
const cardsData = [
  { title: "Flexibilidad ", image: "https://via.placeholder.com/150", description: (
    <>
      <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
      Posibilidad de disponer del Renault que necesitas en cada periodo de tu vida, adaptándolo a las circunstancias de cada 
      momento. 

      </MKTypography>
    </>
  ) },
  { title: "Diseñado para ti", image: "https://via.placeholder.com/150", description: (
    <>
      <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
      Tú eliges la duración y los servicios asociados a tú financiación. 

      </MKTypography>
    </>
  ) },
  { title: "Adaptado a tu presupuesto", image: "https://via.placeholder.com/150", description: (
    <>
      <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
      Posibilidad de elegir la entrada y la cuota que más te convenga y se adapte a tu presupuesto.

      </MKTypography>
    </>
  ) },
  { title: "Más por menos", image: "https://via.placeholder.com/150", description: (
    <>
      <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
      Acceso a gamas más altas gracias a cuotas más asequibles que en una financiación tradicional. 
 


      </MKTypography>
    </>
  ) },
  { title: "Disfruta siempre con lo más nuevo de Renault ", image: "https://via.placeholder.com/150", description: (
    <>
      <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
      Dado que al finalizar el contrato existe la opción de cambiar el coche por uno nuevo -en condiciones especialmente 
      ventajosas para ti- siempre podrás disfrutar de los últimos desarrollos y de la última tecnología de la marca y del 
      mercado.

      </MKTypography>
    </>
  ) }

];

const Carousel = () => {
  // Configuración de react-slick
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Slider
        {...settings}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {cardsData.map((card, index) => (
          <Box
            key={index}
            sx={{
              padding: '0px 30px',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    ></div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {card.title}
                        </h2>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {card.description}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Carousel;
