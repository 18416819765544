import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import TextField from "@mui/material/TextField";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import Breadcrumbs from "examples/Breadcrumbs";
import routes from "routes";
import footerRoutes from "footer.routes";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgImage from "assets/images/bg-presentation-renault-2.jpg";
import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import MKBadge from "components/MKBadge";
import LogoSVG from "assets/images/kode_logo.svg";
import abstract from "assets/images/abstract.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CenteredBlogCardEquip from "examples/Cards/BlogCards/CenteredBlogCardEquip";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import icon_engine from "assets/images/icon-engine.png";
import icon_gear from "assets/images/icon-gear.png";
import icon_delivery from "assets/images/icon-delivery.png";
import { useMediaQuery } from '@mui/material';
import ProductDetailMobile from "./ProductDetailMobile";
import ProductDetailDesktop from "./ProductDetailDesktop";
function ProductDetail() {
 

  const isSmallDevice = useMediaQuery('(max-width:866px)');
  return (
    <>

     {isSmallDevice ? <ProductDetailMobile/> : <ProductDetailDesktop/> }
    </>
  );
}

export default ProductDetail;
