import React from 'react';
import { Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import kode_logo from '../../../assets/images/kode_logo.svg';

const theme = createTheme({
  palette: {
    background: {
      default: '#000000',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

const FullPageMessage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'background.default',
          color: 'text.primary',
          textAlign: 'center', // Centra el texto
        }}
      >
        <Box mb={4}>
          <img src={kode_logo} alt="Kode Logo" width="85%" />
        </Box>
        <Typography sx={{fontFamily: 'Rodina-Regular' , lineHeight: 1}} variant="h3" component="h1" gutterBottom>
          Dominio en Pruebas
        </Typography>
        <Typography sx={{fontFamily: 'Rodina-Regular', lineHeight:'1.5px'}} variant="h6" component="h2">
          ¡Nos vemos muy pronto!
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default FullPageMessage;
