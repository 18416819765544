import React, { useState, useEffect, useTransition } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import LogoSVG from "assets/images/kode_logo.svg";

const pages = [
  { title: "Vehículos", link: "/#componente-b" },
  { title: "Usados", link: "https://www.coches.net/concesionario/kodecarsmarket/default/" },
  { title: "Preference", link: "/preference" },
  { title: "Financiación", link: "/" },
  { title: "Mi primer coche", link: "/autoescuela" },
  { title: "Blog", link: "/blog" },
];

function ResponsiveAppBar({ prodRef, onCambio }) {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const location = useLocation();

  // Función para manejar la lógica de clics
  const handleClick = (page) => {
    if (page?.title === 'Usados' && page?.link) {
      // Abrir el link de 'Usados' en una nueva pestaña
      window.open(page.link, "_blank");
    } else if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (page?.link) {
      // Navegar a la página proporcionada
      startTransition(() => {
        navigate(page.link);
      });
    } else {
      startTransition(() => {
        navigate('/');
      });
    }
  };

  const [isPending, startTransition] = useTransition();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsScrolled(true);
        onCambio(true);
      } else {
        setIsScrolled(false);
        onCambio(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onCambio]);

  return (
    <AppBar
      position="static"
      sx={{
        width: { xs: "90%", sm: "90%", md: "90%", lg: "80%" },
        margin: "auto",
        borderRadius: "25px",
        backgroundColor: isScrolled ? "info" : "transparent",
        backdropFilter: isScrolled ? "saturate(200%) blur(30px)" : "none",
        transition: "background-color 1.0s ease, backdrop-filter 1.0s ease",
        boxShadow: isScrolled ? "0px 4px 20px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            justifyContent: "space-between",
            marginX: "auto",
          }}
        >
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="white"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiMenu-paper": {
                  backgroundColor: "#181818 !important",
                  color: "#ffffff",
                },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={() => handleClick(page)}>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      textAlign: "center",
                      transition: "color 0.3s ease",
                    }}
                  >
                    {page.title}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Logo */}
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleClick();
            }}
          >
            <img
              alt="Logo"
              src={LogoSVG}
              style={{
                width: "120px",
                height: "60px",
                transition: "all 0.3s ease",
              }}
            />
          </Box>

          {/* Menu Buttons */}
          <Box
            sx={{
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.title}
                onClick={() => {
                  if (page.title === "Usados") {
                    window.open(page.link, "_blank");
                  } else if (page.title === "Vehículos" && location.pathname === '/') {
                    prodRef(); // Ir a productos
                  } else {
                    startTransition(() => {
                      navigate(page.link);
                  });

                  }
                }}
                sx={{
                  my: 2,
                  color: "#ffffff",
                  display: "block",
                  "&:hover": {
                    color: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
