import React from 'react';
import { Card, CardContent, Typography, CardMedia } from '@mui/material';

const YouTubeCard = ({ videoId, title }) => {
  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 2 ,}}>
      <CardMedia
        component="iframe"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <CardContent>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default YouTubeCard;
