import * as React from "react";
import { Suspense, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import { Link as MuiLink } from "@mui/material";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import bgImage from "assets/images/bg-presentation-renault-x.jpg";
import bgRenault from "assets/images/bg-renault.webp";
import { Helmet } from "react-helmet";

import Dudando from "assets/images/Dudando.webp";
import PadreHijo from "assets/images/Padre HIjo.webp";
import NewCar from "assets/images/New Car.webp";

import Historial from "assets/images/Historia.webp";
import Kilometraje from "assets/images/Kilometraje.webp";
import Mantenimiento from "assets/images/Mantenimiento.webp";

import Accidente from "assets/images/Accidente.webp";
import Factura from "assets/images/Factura.webp";
import Tirada from "assets/images/Tirada.webp";

import Averia from "assets/images/Averias.webp";
import Reparaciones from "assets/images/Seguro.webp";
import Garantia from "assets/images/Garantia.webp";

import CenteredFooter from "examples/Footers/CenteredFooter";
import YouTubeCard from "pages/Presentation/sections/YouTube";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function KodeCookies() {
  const navigate = useNavigate();
  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    //console.log(nuevoValor);
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };

  return (
    <>
      <Helmet>
        <title>Kode Cars Market - Política de Cookies</title>
      </Helmet>
      <Box sx={{ backgroundColor: "#181818", minHeight: "100vh" }}>
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          {
            <ResponsiveAppBar
              reservationDrawer={reservationDrawer}
              onCambio={manejarCambio}
            />
          }
        </Box>
        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), #181818), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`,
                  },
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ maxWidth: "100%" }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1,
                        fontSize: {
                          xs: "2.5rem",
                          sm: "3rem",
                          md: "4rem",
                          lg: "5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      Política de Cookies
                    </MKTypography>
                    <MKTypography
                      color="white"
                      variant="h3"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1.5,
                        fontSize: {
                          xs: "1.5rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2.5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    ></MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>

        {/* Cookie Policy Section */}
        <Container
          sx={{
            mt: {
              xs: -2,
              sm: -2,
              md: -2,
              lg: -14,
            },
            mb: 8,
            backgroundColor: "white",
            color: "#ffffff",
          }}
        >
          <p>
            En Kode Cars Market, utilizamos cookies para mejorar la experiencia
            de los usuarios en nuestro sitio web y con fines analíticos. Al
            utilizar nuestro sitio, aceptas el uso de cookies según lo descrito
            en esta política.
          </p>

          <h2>1. ¿Qué son las cookies?</h2>
          <p>
            Las cookies son pequeños archivos de texto que se almacenan en tu
            dispositivo cuando visitas un sitio web. Su función es recordar tus
            preferencias y mejorar la funcionalidad de nuestro sitio.
          </p>

          <h2>2. Tipos de cookies que utilizamos</h2>
          <ul>
            <li>
              <strong>Cookies esenciales:</strong> Son necesarias para que el
              sitio funcione correctamente, como para mantener tus preferencias
              de navegación.
            </li>
            <li>
              <strong>Cookies de análisis:</strong> Nos permiten mejorar el
              rendimiento del sitio mediante la recopilación de datos anónimos
              sobre cómo interactúan los usuarios con él.
            </li>
          </ul>

          <h2>3. Cómo gestionar las cookies</h2>
          <p>
            Puedes configurar tu navegador para rechazar o eliminar cookies. No
            obstante, esto podría afectar la funcionalidad de algunas áreas del
            sitio.
          </p>

          <h2>4. Consentimiento</h2>
          <p>
            Al continuar navegando en nuestro sitio, aceptas el uso de cookies
            de acuerdo con esta política.
          </p>
        </Container>
        <MKBox bgColor="info" mt={4}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              {<CenteredFooter pt={6} px={1} />}
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default KodeCookies;
