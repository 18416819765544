import * as React from "react";
import { Suspense, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import { Link as MuiLink } from "@mui/material";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import bgImage from "assets/images/bg-presentation-renault-x.jpg";
import bgRenault from "assets/images/bg-renault.webp";
import { Helmet } from "react-helmet";

import Dudando from "assets/images/Dudando.webp";
import PadreHijo from "assets/images/Padre HIjo.webp";
import NewCar from "assets/images/New Car.webp";

import Historial from "assets/images/Historia.webp";
import Kilometraje from "assets/images/Kilometraje.webp";
import Mantenimiento from "assets/images/Mantenimiento.webp";

import Accidente from "assets/images/Accidente.webp";
import Factura from "assets/images/Factura.webp";
import Tirada from "assets/images/Tirada.webp";

import Averia from "assets/images/Averias.webp";
import Reparaciones from "assets/images/Seguro.webp";
import Garantia from "assets/images/Garantia.webp";

import CenteredFooter from "examples/Footers/CenteredFooter";
import YouTubeCard from "pages/Presentation/sections/YouTube";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function KodePrivacidad() {
  const navigate = useNavigate();
  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    //console.log(nuevoValor);
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };

  return (
    <>
      <Helmet>
        <title>Kode Cars Market - Política de Privacidad</title>
      </Helmet>
      <Box sx={{ backgroundColor: "#181818", minHeight: "100vh" }}>
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          {
            <ResponsiveAppBar
              reservationDrawer={reservationDrawer}
              onCambio={manejarCambio}
            />
          }
        </Box>
        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), #181818), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`,
                  },
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ maxWidth: "100%" }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1,
                        fontSize: {
                          xs: "2.5rem",
                          sm: "3rem",
                          md: "4rem",
                          lg: "5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      Política de Privacidad
                    </MKTypography>
                    <MKTypography
                      color="white"
                      variant="h3"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1.5,
                        fontSize: {
                          xs: "1.5rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2.5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    ></MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>

        <Container
          sx={{
            mt: {
              xs: -2,
              sm: -2,
              md: -2,
              lg: -14,
            },
            mb: 8,
            backgroundColor: "white",
            color: "#ffffff",
          }}
        >
          <section>
            <p>
              En Kode Cars Market, nos comprometemos a proteger tu privacidad y
              la información personal que compartes con nosotros. Esta política
              de privacidad describe cómo recopilamos, utilizamos y protegemos
              tus datos al usar nuestro sitio web.
            </p>
          </section>

          <section>
            <h2>1. Información que recopilamos</h2>
            <p>Recopilamos los siguientes tipos de información:</p>
            <ul>
              <li>
                Datos personales, como tu nombre, correo electrónico y número de
                teléfono, cuando te pones en contacto con nosotros para
                solicitar información o asistencia en la gestión de un vehículo
                Renault.
              </li>
              <li>
                Información técnica, como la dirección IP, el navegador y el
                sistema operativo que utilizas al navegar por nuestro sitio web.
              </li>
              <li>
                Detalles sobre tu interés en vehículos o solicitudes de
                contacto.
              </li>
            </ul>
          </section>

          <section>
            <h2>2. Uso de la información</h2>
            <p>La información que recopilamos se utiliza para:</p>
            <ul>
              <li>
                Gestionar tu solicitud de información sobre vehículos Renault
                disponibles en stock.
              </li>
              <li>
                Facilitar la comunicación entre tú y Renault para la compra del
                vehículo, si así lo solicitas.
              </li>
              <li>
                Mejorar nuestro sitio web y servicios basados en tu experiencia
                y comentarios.
              </li>
            </ul>
          </section>

          <section>
            <h2>3. Protección de la información</h2>
            <p>
              Adoptamos medidas de seguridad apropiadas para proteger tus datos
              personales frente a accesos no autorizados, alteraciones o
              divulgación.
            </p>
          </section>

          <section>
            <h2>4. Compartir información</h2>
            <p>
              No vendemos, alquilamos ni compartimos tus datos personales con
              terceros. Sin embargo, tu información podría compartirse con
              Renault para facilitar el proceso de gestión de la compra del
              vehículo que te interesa.
            </p>
          </section>

          <section>
            <h2>5. Derechos de los usuarios</h2>
            <p>
              Tienes derecho a acceder, corregir o eliminar tu información
              personal en cualquier momento. Para ejercer estos derechos, por
              favor contáctanos en{" "}
              <a href="mailto:info@kodecarsmarket.com">
                info@kodecarsmarket.com
              </a>
              .
            </p>
          </section>

          <section>
            <h2>6. Cambios en la política</h2>
            <p>
              Nos reservamos el derecho de modificar esta política de privacidad
              en cualquier momento. Publicaremos cualquier cambio en esta
              página.
            </p>
          </section>
        </Container>
        <MKBox bgColor="info" mt={4}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              {<CenteredFooter pt={6} px={1} />}
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default KodePrivacidad;
