import React, { useState } from "react";
import { Box, Grid, Card, CardMedia, Typography } from "@mui/material";
import { styled } from "@mui/system";
import etc from "assets/images/etc_large.png";
import et0 from "assets/images/et0_large.png";
import eteco from "assets/images/eteco_large.png";
import MKTypography from "components/MKTypography";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MKBox from "components/MKBox";
import "./slick.css";

const images = [
  {
    src: et0,
    alt: "Etiqueta 0",
    text: "Tendrán derecho a la obtención del distintivo ambiental Cero Emisiones los vehículos registrados como eléctricos de batería (BEV), eléctricos de autonomía extendida (REEV), híbridos enchufables (PHEV) con una autonomía mínima de 40 km, o vehículos de pila de combustible.",
  },
  {
    src: eteco,
    alt: "Etiqueta ECO",
    text: "Tendrán derecho al distintivo ambiental ECO los turismos y comerciales ligeros híbridos enchufables con menos de 40 km de autonomía, híbridos no enchufables (HEV) o vehículos de gas natural (GNC o GLP). Los de gasolina deben cumplir las normativas EURO 4, 5 o 6, y los diésel la EURO 6. También lo obtendrán los vehículos de más de 8 plazas y de transporte de mercancías híbridos o de gas que cumplan la norma EURO 6. Los vehículos ligeros (categoría L) deben ser híbridos enchufables con autonomía menor a 40 km o híbridos no enchufables.",
  },
  {
    src: etc,
    alt: "Etiqueta C",
    text: "Tendrán derecho a la obtención del distintivo ambiental C (Verde) los turismos y comerciales ligeros registrados que cumplan con las normativas de emisiones gasolina EURO 4, 5 o 6, y diésel EURO 6. También podrán obtenerlo los vehículos de más de 8 plazas y de transporte de mercancías con nivel de emisiones EURO 6, independientemente del tipo de combustible. Los vehículos ligeros (categoría L) deberán cumplir con las normativas de emisiones EURO 3 o 4.",
  },
];

const ImageCard = styled(Card)(({ theme, hoverEffect }) => ({
  position: "relative",
  overflow: "hidden",
  transition: "transform 0.3s ease, opacity 0.3s ease, width 0.3s ease, height 0.3s ease",
  backgroundColor: "transparent",
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: hoverEffect ? "400px" : "300px",  // Aumenta el tamaño al hacer hover
  maxHeight: hoverEffect ? "auto" : "300px",  // Aumenta el tamaño dinámicamente para mostrar el texto
  opacity: hoverEffect ? 0.9 : 1, // Ajusta la opacidad en hover
}));

const HoverText = styled(Typography)(({ theme, hoverEffect }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  minHeight: "100%",
  height: "auto",  
  backgroundColor: "#181818",
  //padding: "16px",
  color: "white",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  opacity: hoverEffect ? 1 : 0,
  transition: "opacity 0.3s ease",
  pointerEvents: hoverEffect ? "auto" : "none",
  boxSizing: "border-box",
}));



const HoverEffectGrid = () => {
  const [hovered, setHovered] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  const carouselSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    arrows: false,
    dotsClass: "button__bar",


  };

  return (
    <Box sx={{ width: "100%", padding: 0 }}>
      <Box sx={{ width: { xs: "100%", sm: "90%", lg: "80%" }, mx: "auto" }}>
        <Box mb={5} lineHeight={1}>
          <MKTypography color="white" variant="h3" align="center" gutterBottom>
            DISTINTIVOS AMBIENTALES
          </MKTypography>
          <MKTypography
            sx={{ color: "#ffffff" }}
            variant="body2"
            align="center"
          >
            En España, los vehículos se clasifican según su nivel de emisiones para reducir la contaminación y mejorar la calidad del aire. Este sistema de etiquetado medioambiental distingue a los coches por su impacto ecológico, asignándoles una etiqueta que otorga beneficios como el acceso a zonas de bajas emisiones o ventajas fiscales. Conocer estos distintivos es clave para tomar decisiones sostenibles e informadas al elegir tu vehículo.{" "}
          </MKTypography>
        </Box>

        {isMobile ? (
          <Slider {...carouselSettings} sx={{
            "& li button::before": {
      color: "#888", // Color de los dots inactivos
      fontSize: "12px",
    },
    "& li.slick-active button::before": {
      color: "#ff0000", // Color de los dots activos
      fontSize: "16px",
    },
    "& li button:focus:not(:hover)": {
      backgroundColor: "#ffaaaa", // Color del fondo cuando el dot está en focus y no se está haciendo hover
      boxShadow: `0px 0px 6px 3px rgba(255,0,0,0.5)`, // Sombra personalizada (ajústala según prefieras)
    },
          }}>
            {images.map((image, index) => (
              <Box
                p={2}
                sx={{
                  textAlign: "center",
                  py: 2,
                  marginTop:'60px',
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  position: "relative", // Esto asegura que el número grande se posicione relativo a cada slide
                }}
              >
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                    alignItems="center"
                    
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                        alignItems:"center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={image.src}
                        alt={"fff"}
                        alignItems="center"
                        style={{
                          maxHeight: "250px",
                          width: isMobile ? "80%" : '55%',
                          borderRadius: "0",
                          objectFit: "cover",
                          objectPosition: "50% 30%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                  
                        <br />
                        <MKBox>
                          <MKTypography
                          variant="body2"
                            sx={{ color: "#ffffff", textAlign: "center" }}
                          >
                            {image.text}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Box>
            ))}
          </Slider>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            {" "}
            {/* Centrando las imágenes */}
            {images.map((image, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                key={index}
                sx={{
                  marginTop:'30px',
                  //display: "flex",
                  //justifyContent: "center",
                  //alignItems: "center", // Centrando cada Grid item
                }}
              >
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                    alignItems="center"
                    
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                        alignItems:"center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={image.src}
                        alt={"fff"}
                        alignItems="center"
                        style={{
                          maxHeight: "250px",
                          width: isMobile ? "100%" : '55%',
                          borderRadius: "0",
                          objectFit: "cover",
                          objectPosition: "50% 30%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                  
                        <br />
                        <MKBox>
                          <MKTypography
                          variant="body2"
                            sx={{ color: "#ffffff", textAlign: "center" }}
                          >
                            {image.text}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default HoverEffectGrid;
