

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import './assets/fonts/fonts.css';  // Importa el archivo CSS de las fuentes
import ReactGA from 'react-ga4'; // Importamos react-ga4

const container = document.getElementById("root");

// Inicializar Google Analytics solo en producción
if (process.env.NODE_ENV === 'production') {
  const gaId = process.env.REACT_APP_GA_ID; // Utilizar la variable de entorno
  ReactGA.initialize(gaId);  // Inicializar Google Analytics con el ID de entorno
}
// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>
);
