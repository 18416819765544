import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import icon_engine from "assets/images/icon-engine.png";
import icon_gear from "assets/images/icon-gear.png";
import icon_delivery from "assets/images/icon-delivery.png";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import MKBadge from "components/MKBadge";
import { Margin } from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormControl,
  FormGroup,
  FormHelperText,
  Divider,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { ArrowBack, ArrowForward } from "@mui/icons-material";

const SelectorDinamico = ({ carSetSon }) => {
  const [modelos, setModelos] = useState([]);
  const [versiones, setVersiones] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [colores, setColores] = useState([]);
  const [equipamientos, setEquipamientos] = useState([]);
  const [modeloSeleccionado, setModeloSeleccionado] = useState([]);
  const [versionSeleccionada, setVersionSeleccionada] = useState([]);
  const [colorSeleccionado, setColorSeleccionado] = useState([]);
  const [equipamientoSeleccionado, setEquipamientoSeleccionado] = useState([]);
  const [motores, setMotores] = useState([]); // Ahora motores es un array
  const [medios, setMedios] = useState([]); // Ahora motores es un array
  const [mediosSeleccionado, setMediosSeleccionado] = useState([]); // Ahora motores es un array
  const [cambio, setCambio] = useState([]); // Ahora cambio es un array para múltiples selecciones
  const [cambioSeleccionado, setCambioSeleccionado] = useState([]); // Ahora cambio es un array para múltiples selecciones
  const [motorSeleccionado, setMotorSeleccionado] = useState([]); // Ahora cambio es un array para múltiples selecciones
  const [typeSeleccionado, setTypeSeleccionado] = useState("Turismo");
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  useEffect(() => {
    obtenerMedios();
    obtenerCambios();
    obtenerMotores();
    obtenerColores();
  }, [typeSeleccionado]);

  useEffect(() => {
    obtenerColores(modeloSeleccionado);
  }, [modeloSeleccionado]);

  useEffect(() => {
    carSetSon(
      typeSeleccionado,
      modeloSeleccionado,
      versionSeleccionada,
      colorSeleccionado,
      motorSeleccionado,
      cambioSeleccionado,
      mediosSeleccionado
    );
    console.log("filtros", [
      typeSeleccionado,
      motorSeleccionado,
      versionSeleccionada,
      cambioSeleccionado,
      mediosSeleccionado,
      colorSeleccionado,
      modeloSeleccionado,
    ]);
  }, [
    typeSeleccionado,
    motorSeleccionado,
    versionSeleccionada,
    cambioSeleccionado,
    mediosSeleccionado,
    colorSeleccionado,
    modeloSeleccionado,
  ]);

  useEffect(() => {
    obtenerModelos(
      typeSeleccionado,
      motorSeleccionado,
      cambioSeleccionado,
      mediosSeleccionado
    );
  }, [
    typeSeleccionado,
    motorSeleccionado,
    cambioSeleccionado,
    mediosSeleccionado,
  ]);

  useEffect(() => {
    if (modeloSeleccionado) {
      obtenerVersiones(
        modeloSeleccionado,
        motorSeleccionado,
        cambioSeleccionado,
        mediosSeleccionado
      );
      if (versionSeleccionada) {
        obtenerColores(versionSeleccionada);
      } else {
        setColores([]);
      }
    } else {
      setVersiones([]);
    }
  }, [
    typeSeleccionado,
    modeloSeleccionado,
    versionSeleccionada,
    colorSeleccionado,
    motorSeleccionado,
    cambioSeleccionado,
    mediosSeleccionado,
  ]);

  const obtenerMedios = async () => {
    try {
      const response = await fetch(
        `https://kodeapp.onrender.com/api/medios?type=${typeSeleccionado}`
      );
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setMedios(data.medios || []); // Asegúrate de que no se seleccione ningún medio por defecto
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const obtenerCambios = async () => {
    try {
      const response = await fetch(
        `https://kodeapp.onrender.com/api/cambios?type=${typeSeleccionado}`
      );
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setCambio(data.Cambios || []); // Asegúrate de que no se seleccione ningún medio por defecto
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const obtenerMotores = async () => {
    try {
      const response = await fetch(
        `https://kodeapp.onrender.com/api/motores?type=${typeSeleccionado}`
      );
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setMotores(data.Motores || []); // Asegúrate de que no se seleccione ningún medio por defecto
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const obtenerModelos = (
    typeSeleccionado,
    motorSeleccionado,
    cambioSeleccionado,
    mediosSeleccionado
  ) => {
    const motoresQuery =
      motorSeleccionado.length > 0
        ? encodeURIComponent(motorSeleccionado.join(","))
        : "";
    const cambioQuery =
      cambioSeleccionado.length > 0
        ? encodeURIComponent(cambioSeleccionado.join(","))
        : "";
    const mediosQuery =
      mediosSeleccionado.length > 0
        ? encodeURIComponent(mediosSeleccionado.join(","))
        : "";

    axios
      .get(`https://kodeapp.onrender.com/api/modelos?type=${typeSeleccionado}`)
      .then((response) => {
        // Ordenar los modelos alfabéticamente por el campo 'nombre'
        const modelosOrdenados = response.data.sort((a, b) => {
          if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
          if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
          return 0;
        });

        // Guardar los modelos ordenados
        setModelos(modelosOrdenados);
      })
      .catch((error) => {
        console.error("Error al obtener modelos:", error);
      });
  };

  const obtenerVersiones = async (
    modeloId,
    motorSeleccionado,
    cambioSeleccionado,
    mediosSeleccionado
  ) => {
    //console.log(`https://kodeapp.onrender.com/api/versiones?modelo_id=${modeloId}&motor=${motoresQuery}&cambio=${cambioQuery}&medio=${mediosQuery}`)
    try {
      const response = await fetch(
        `https://kodeapp.onrender.com/api/versiones?modelo_id=${modeloId}`
      );
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setVersiones(data || []);
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const agruparColores = (colores) => {
    const coloresMap = {}; // Mapeo para agrupar por nombre

    colores.forEach((color) => {
      const nombre = color.nombre;

      // Si ya existe un color con este nombre, añadimos el id al arreglo de ids
      if (coloresMap[nombre]) {
        coloresMap[nombre].ids.push(color.id);
      } else {
        // Si es el primer color con este nombre, creamos el grupo
        coloresMap[nombre] = {
          nombre: nombre,
          ids: [color.id],
          img_color: color.img_color, // Mantenemos la primera imagen
        };
      }
    });

    //console.log(Object.values(coloresMap))
    // Convertimos el mapeo en un arreglo
    return Object.values(coloresMap);
  };

  const obtenerColores = async (modeloId) => {
    try {
      const response = await fetch(
        `https://kodeapp.onrender.com/api/colores?type=${typeSeleccionado}&modelo_id=${modeloId}`
      );
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setColores(agruparColores(data.colores));
      //const coloresAgrupados = agruparColores(data.colores);
      //console.log(coloresAgrupados);
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setTypeSeleccionado(value);
  };

  const handleMotoresChange = (e) => {
    const value = parseInt(e.target.value);
    setMotorSeleccionado(
      (prevState) =>
        prevState.includes(value)
          ? prevState.filter((c) => c !== value) // Remover si ya está seleccionado
          : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleModeloChange = (e) => {
    const value = parseInt(e.target.value);
    setModeloSeleccionado(
      (prevState) =>
        prevState.includes(value)
          ? prevState.filter((c) => c !== value) // Remover si ya está seleccionado
          : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleVersionChange = (e) => {
    const value = parseInt(e.target.value);
    setVersionSeleccionada(
      (prevState) =>
        prevState.includes(value)
          ? prevState.filter((c) => c !== value) // Remover si ya está seleccionado
          : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleCambioChange = (e) => {
    const value = parseInt(e.target.value);
    setCambioSeleccionado(
      (prevState) =>
        prevState.includes(value)
          ? prevState.filter((c) => c !== value) // Remover si ya está seleccionado
          : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleMediosImageClick = (medio) => {
    setMediosSeleccionado(
      (prevState) =>
        prevState.includes(medio.id)
          ? prevState.filter((c) => c !== medio.id) // Remover si ya está seleccionado
          : [...prevState, medio.id] // Agregar si no está seleccionado
    );
  };

  const handleColoresImageClick = (color) => {
    setColorSeleccionado((prevState) => {
      const flatIds = color.ids;
      const nuevosSeleccionados = prevState.some((id) => flatIds.includes(id))
        ? prevState.filter((id) => !flatIds.includes(id)) // Deseleccionar si ya está seleccionado
        : [...prevState, ...flatIds]; // Seleccionar todos los IDs del grupo de colores

      return nuevosSeleccionados;
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container display="flex"
          alignItems="flex-start"
          justifyContent="center"
          mt={-2}
          mb={0} sx={{ width: "100%" }}>
        <Grid item
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            xs={12}
            sm={4}
            md={4}
            lg={12}
            xxl={12}
            p={2}>
          <FormControl
            sx={{ mt: 3, flex: "1 1 45%" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel
              component="legend"
              sx={{
                //color: 'rgba(255, 255, 255, 0.60)',
                color: "#FFFFFF",
                fontSize: "1.1rem",
                fontWeight: "bold",
                "&.Mui-focused": {
                  color: "#FFD415", // Color amarillo cuando está enfocado
                },
                "&.Mui-error": {
                  color: "#FFD415", // Color amarillo cuando hay un error
                },
              }}
            >
              Tipo de vehículo
            </FormLabel>
            <FormGroup sx={{ marginTop: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={typeSeleccionado === "Turismo"}
                    onChange={handleTypeChange}
                    value={"Turismo"}
                    sx={{ color: "rgba(255, 255, 255, 0.60)" }}
                  />
                }
                label={"Turismo"}
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  "& .MuiTypography-root": {
                    color: "rgba(255, 255, 255, 0.60)", // Asegura que el texto de la etiqueta sea blanco
                    fontWeight: "regular",
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={typeSeleccionado === "Comercial"}
                    onChange={handleTypeChange}
                    value={"Comercial"}
                    sx={{ color: "rgba(255, 255, 255, 0.60)" }}
                  />
                }
                label={"Comercial"}
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  "& .MuiTypography-root": {
                    color: "rgba(255, 255, 255, 0.60)", // Asegura que el texto de la etiqueta sea blanco
                    fontWeight: "regular",
                  },
                }}
              />
            </FormGroup>
            <FormHelperText
              sx={{
                color: "rgba(255, 255, 255, 0.60)",
                fontSize: "0.8rem",
                marginTop: "10px",
              }}
            >
              Selecciona uno, mas de uno o ninguno
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            xs={12}
            sm={4}
            md={4}
            lg={12}
            xxl={12}
            p={2}>
          <Divider sx={{ backgroundColor: "#FFFFFF" }} />

          <FormControl
            sx={{ mt: 3, flex: "1 1 45%" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel
              component="legend"
              sx={{
                //color: 'rgba(255, 255, 255, 0.60)',
                color: "#FFFFFF",
                fontSize: "1.1rem",
                fontWeight: "bold",
                "&.Mui-focused": {
                  color: "#FFD415", // Color amarillo cuando está enfocado
                },
                "&.Mui-error": {
                  color: "#FFD415", // Color amarillo cuando hay un error
                },
              }}
            >
              Motorizaciónes
            </FormLabel>
            <FormGroup sx={{ marginTop: "10px" }}>
              {motores.map((m) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={motorSeleccionado.includes(m.id)}
                      onChange={handleMotoresChange}
                      value={m.id}
                      sx={{ color: "rgba(255, 255, 255, 0.60)" }}
                    />
                  }
                  label={m.nombre.charAt(0).toUpperCase() + m.nombre.slice(1)}
                  sx={{
                    color: "rgba(255, 255, 255, 0.60)",
                    "& .MuiTypography-root": {
                      color: "rgba(255, 255, 255, 0.60)", // Asegura que el texto de la etiqueta sea blanco
                      fontWeight: "regular",
                    },
                  }}
                />
              ))}
            </FormGroup>
            <FormHelperText
              sx={{
                color: "rgba(255, 255, 255, 0.60)",
                fontSize: "0.8rem",
                marginTop: "10px",
              }}
            >
              Selecciona uno, mas de uno o ninguno
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            xs={12}
            sm={4}
            md={4}
            lg={12}
            xxl={12}
            p={2}>
          <Divider sx={{ backgroundColor: "#FFFFFF" }} />
          <FormControl
            sx={{ mt: 3, flex: "1 1 45%" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel
              component="legend"
              sx={{
                //color: 'rgba(255, 255, 255, 0.60)',
                color: "#FFFFFF",
                fontSize: "1.2rem",
                fontWeight: "bold",
                "&.Mui-focused": {
                  color: "#FFD415", // Color amarillo cuando está enfocado
                },
                "&.Mui-error": {
                  color: "#FFD415", // Color amarillo cuando hay un error
                },
              }}
            >
              Transmisiones
            </FormLabel>
            <FormGroup sx={{ marginTop: "10px" }}>
              {cambio.map((c) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cambioSeleccionado.includes(c.id)}
                      onChange={handleCambioChange}
                      value={c.id}
                      sx={{ color: "rgba(255, 255, 255, 0.60)" }}
                    />
                  }
                  label={c.nombre.charAt(0).toUpperCase() + c.nombre.slice(1)}
                  sx={{
                    color: "rgba(255, 255, 255, 0.60)",
                    "& .MuiTypography-root": {
                      color: "rgba(255, 255, 255, 0.60)", // Asegura que el texto de la etiqueta sea blanco
                      fontWeight: "regular",
                    },
                  }}
                />
              ))}
            </FormGroup>
            <FormHelperText
              sx={{
                color: "rgba(255, 255, 255, 0.60)",
                fontSize: "0.8rem",
                marginTop: "10px",
              }}
            >
              Selecciona uno, mas de uno o ninguno
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            xs={12}
            sm={4}
            md={4}
            lg={12}
            xxl={12}
            p={2}>
          <Divider sx={{ backgroundColor: "#FFFFFF" }} />
          <FormControl
            component="fieldset"
            variant="standard"
            sx={{ mt: 3, flex: "1 1 45%" }}
          >
            <FormLabel
              component="legend"
              sx={{
                //color: 'rgba(255, 255, 255, 0.60)',
                color: "#FFFFFF",
                fontSize: "1.2rem",
                fontWeight: "bold",
                "&.Mui-focused": { color: "#FFD415" },
                "&.Mui-error": { color: "#FFD415" },
              }}
            >
              Etiquetas Ambientales
            </FormLabel>
            <FormGroup
              row
              sx={{ marginTop: "10px", justifyContent: "space-around" }}
            >
              {medios.map((medio) => (
                <Box
                  sx={{
                    width: 50,
                    height: 50,
                    border: mediosSeleccionado.includes(medio.id)
                      ? "3px solid #FFD415"
                      : "3px solid transparent",
                    cursor: "pointer",
                    borderRadius: "30px",
                    overflow: "hidden",
                    boxShadow: mediosSeleccionado.includes(medio.id)
                      ? "0px 0px 10px rgba(255, 212, 21, 0.7)"
                      : "none",
                    marginBottom: "10px",
                  }}
                  onClick={() => handleMediosImageClick(medio)}
                >
                  <img
                    src={medio.imagen}
                    alt={medio.id}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              ))}
            </FormGroup>
            <FormHelperText
              sx={{
                color: "rgba(255, 255, 255, 0.60)",
                fontSize: "0.8rem",
                marginTop: "10px",
              }}
            >
              Selecciona uno, más de uno o ninguno
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            xs={12}
            sm={4}
            md={4}
            lg={12}
            xxl={12}
            p={2}>
          <Divider sx={{ backgroundColor: "#FFFFFF" }} />
          <FormControl
            sx={{ mt: 3, flex: "1 1 45%" }}
            component="fieldset"
            variant="standard"
          >
            <FormLabel
              component="legend"
              sx={{
                //color: 'rgba(255, 255, 255, 0.60)',
                color: "#FFFFFF",
                fontSize: "1.2rem",
                fontWeight: "bold",
                "&.Mui-focused": {
                  color: "#FFD415", // Color amarillo cuando está enfocado
                },
                "&.Mui-error": {
                  color: "#FFD415", // Color amarillo cuando hay un error
                },
              }}
            >
              Modelos
            </FormLabel>
            <FormGroup sx={{ marginTop: "10px" }}>
              {modelos.map((m) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={modeloSeleccionado.includes(m.id)}
                      onChange={handleModeloChange}
                      value={m.id}
                      sx={{ color: "rgba(255, 255, 255, 0.60)" }}
                    />
                  }
                  label={m.nombre.charAt(0).toUpperCase() + m.nombre.slice(1)}
                  sx={{
                    color: "rgba(255, 255, 255, 0.60)",
                    "& .MuiTypography-root": {
                      color: "rgba(255, 255, 255, 0.60)", // Asegura que el texto de la etiqueta sea blanco
                      fontWeight: "regular",
                    },
                  }}
                />
              ))}
            </FormGroup>
            <FormHelperText
              sx={{
                color: "rgba(255, 255, 255, 0.60)",
                fontSize: "0.8rem",
                marginTop: "10px",
              }}
            >
              Selecciona uno, mas de uno o ninguno
            </FormHelperText>
          </FormControl>
        </Grid>
        {modeloSeleccionado > 0 && (
          <Grid item
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xxl={12}>
            <Divider sx={{ backgroundColor: "#FFFFFF" }} />
            <FormControl
              sx={{ mt: 3, flex: "1 1 45%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel
                component="legend"
                sx={{
                  //color: 'rgba(255, 255, 255, 0.60)',
                  color: "#FFFFFF",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  "&.Mui-focused": {
                    color: "#FFD415", // Color amarillo cuando está enfocado
                  },
                  "&.Mui-error": {
                    color: "#FFD415", // Color amarillo cuando hay un error
                  },
                }}
              >
                Versiones
              </FormLabel>
              <FormGroup sx={{ marginTop: "10px" }}>
                {versiones.map((v) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={versionSeleccionada.includes(v.id)}
                        onChange={handleVersionChange}
                        value={v.id}
                        sx={{ color: "rgba(255, 255, 255, 0.60)" }}
                      />
                    }
                    label={v.nombre.charAt(0).toUpperCase() + v.nombre.slice(1)}
                    sx={{
                      color: "rgba(255, 255, 255, 0.60)",
                      "& .MuiTypography-root": {
                        color: "rgba(255, 255, 255, 0.60)", // Asegura que el texto de la etiqueta sea blanco
                        fontWeight: "regular",
                      },
                    }}
                  />
                ))}
              </FormGroup>
              <FormHelperText
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "0.8rem",
                  marginTop: "10px",
                }}
              >
                Selecciona uno, mas de uno o ninguno
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid item
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            xs={12}
            sm={4}
            md={4}
            lg={12}
            xxl={12}
            p={2}>
          <Divider sx={{ backgroundColor: "#FFFFFF" }} />
          <FormControl
            component="fieldset"
            variant="standard"
            sx={{ mt: 3, flex: "1 1 45%" }}
          >
            <FormLabel
              component="legend"
              sx={{
                //color: 'rgba(255, 255, 255, 0.60)',
                color: "#FFFFFF",
                fontSize: "1.2rem",
                fontWeight: "bold",
                "&.Mui-focused": { color: "#FFD415" },
                "&.Mui-error": { color: "#FFD415" },
              }}
            >
              Colores
            </FormLabel>
            <FormGroup
              row
              sx={{ marginTop: "10px", justifyContent: "space-around" }}
            >
              {colores.map((color) => (
                <Box
                  key={color.nombre} // Aseguramos que cada grupo de colores tenga una key única
                  sx={{
                    width: 50,
                    margin: "2px",
                    height: 50,
                    border: colorSeleccionado.some((id) =>
                      color.ids.includes(id)
                    )
                      ? "3px solid #FFD415"
                      : "3px solid rgba(255, 255, 255, 0.4)",
                    cursor: "pointer",
                    borderRadius: "30px",
                    overflow: "hidden",
                    boxShadow: colorSeleccionado.some((id) =>
                      color.ids.includes(id)
                    )
                      ? "0px 0px 10px rgba(255, 212, 21, 0.7)"
                      : "none",
                    marginBottom: "10px",
                  }}
                  onClick={() => handleColoresImageClick(color)}
                >
                  <img
                    src={color.img_color}
                    alt={color.nombre} // La etiqueta alt debe describir el nombre del color
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              ))}
            </FormGroup>
            <FormHelperText
              sx={{
                color: "rgba(255, 255, 255, 0.60)",
                fontSize: "0.8rem",
                marginTop: "10px",
              }}
            >
              Selecciona uno, más de uno o ninguno
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectorDinamico;
