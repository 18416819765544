import * as React from "react";
import { Suspense, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import { Link as MuiLink } from "@mui/material";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import bgImage from "assets/images/renault-5.webp";
import bgRenault from "assets/images/bg-renault.webp";
import bgRenault2 from "assets/images/Renault-Scenic.jpg";
import backets from "assets/images/backets.webp";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import bgImagemanch from "assets/images/brush.webp";
import { Helmet } from "react-helmet";

import Dudando from "assets/images/Dudando.webp";
import PadreHijo from "assets/images/Padre HIjo.webp";
import NewCar from "assets/images/New Car.webp";

import Historial from "assets/images/Historia.webp";
import Kilometraje from "assets/images/Kilometraje.webp";
import Mantenimiento from "assets/images/Mantenimiento.webp";

import Accidente from "assets/images/Accidente.webp";
import Factura from "assets/images/Factura.webp";
import Tirada from "assets/images/Tirada.webp";

import Averia from "assets/images/Averias.webp";
import Reparaciones from "assets/images/Seguro.webp";
import Garantia from "assets/images/Garantia.webp";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CenteredFooter from "examples/Footers/CenteredFooter";
import YouTubeCard from "pages/Presentation/sections/YouTube";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Carousel from "./slider";
function KodePreference() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const handleToggleAccordion = () => {
    setExpanded(!expanded);
  };
  const handleCloseAccordion = () => {
    setExpanded(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));
  const textsCards = [
    {
      title: "Sin entrada mínima",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Compra tu Renault sin entrada mínima. Nos adaptamos a tu presupuesto
            con las mejores condiciones. ¡Estrena coche sin complicaciones!
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: "Dudando",
    },
    {
      title: "Adaptado a tu presupuesto",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Conduce tu Renault nuevo con cuotas mensuales a tu medida. ¡Olvídate
            de pagos elevados y disfruta al máximo desde el primer día!
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: PadreHijo,
    },
    {
      title: "Al final del contrato tú decides",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Devuelve tu Renault sin compromisos, quédate con él o cámbialo por
            uno nuevo con condiciones exclusivas. ¡Tú decides!
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: NewCar,
    },
  ];
  const textsCards2 = [
    {
      title: "1. Cambiar tu coche por uno nuevo",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Cambia tu Renault por uno nuevo. Entrega el tuyo y disfruta de
            ofertas exclusivas, sin comisión de apertura y con descuentos
            especiales diseñados para ti. ¡Renueva tu Renault fácilmente y
            estrena coche nuevo!
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: "Dudando",
    },
    {
      title: "2. Quedarte tu Renault",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            ¿Quieres seguir disfrutando de tu Renault? Solo paga o financia la
            última cuota del contrato Renault Preference y será tuyo. Si decides
            financiarlo, obtendrás condiciones exclusivas de Mobilize Financial
            Services, diseñadas especialmente para ti.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: PadreHijo,
    },
    {
      title: "3. Devolver el coche",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Si decides devolver tu Renault, es muy fácil. Solo entrégalo como
            pago de la última cuota de tu contrato Renault Preference y listo.
            ¡Sin complicaciones!
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: NewCar,
    },
  ];
  const textsCards3 = [
    {
      title: "Mantenimiento y garantía",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Cubre todas las operaciones según las fichas de mantenimiento y
            garantía del fabricante. Las revisiones se realizarán en la{" "}
            <a
              style={{ color: "#FFD415" }}
              href="https://www.renault.es/concesionarios.html"
            >
              Red Oficial Renault
            </a>{" "}
            y los costes ya están incluidos en tus cuotas.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: "Dudando",
    },
    {
      title: "Seguro a Todo Riesgo con Franquicia de 600€",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Cubre los daños propios en caso de accidente. Si reparas en la{" "}
            <a
              style={{ color: "#FFD415" }}
              href="https://www.renault.es/concesionarios.html"
            >
              Red Oficial Renault
            </a>
            , obtendrás un 50% de descuento en la franquicia, quedando en solo
            300€.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: PadreHijo,
    },
  ];

  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    //console.log(nuevoValor);
  };

  /*const isMobileDevice = () => {
  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };*/

  return (
    <>
       <Helmet>
        <title>
          Kode Cars Market - Preference
        </title>
      </Helmet>
      <Box sx={{ backgroundColor: "#181818", minHeight: "100vh" }}>
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          {
            <ResponsiveAppBar
              reservationDrawer={reservationDrawer}
              onCambio={manejarCambio}
            />
          }
        </Box>
        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
            position: "relative", // Necesario para el pseudo-elemento
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 00%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 70%)
      `,
            },
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`,
                  },
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ maxWidth: "100%" }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1,
                        fontSize: {
                          xs: "2.5rem",
                          sm: "3rem",
                          md: "4rem",
                          lg: "5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      RENAULT PREFRENCE
                    </MKTypography>
                    <MKTypography
                      color="white"
                      variant="h3"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1.5,
                        fontSize: {
                          xs: "1.5rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2.5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      Compra tu nuevo Renault con la mejor opción finaciera
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>
        {/* Cookie Policy Section */}
        <Container
          sx={{
            mt: {
              xs: -2,
              sm: -2,
              md: -2,
              lg: -14,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    ></div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Box
          sx={{
            minHeight: "99vh",
            width: "100%",
            backgroundImage: `url(${bgRenault})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
            position: "relative", // Necesario para el pseudo-elemento
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 10%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 20%)
      `,
            },
          }}
        >
          {/* El contenido dentro de la caja */}
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid container sx={{ width: "80%" }} spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={4}></Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={4}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
                p={0}
                sx={{ overflow: "hidden" }}
              >
                <Box sx={{ width: "100%" }}>
                  <Grid container sx={{ width: "100%" }} spacing={2}>
                    <Grid p={0} item xs={12} lg={12} sx={{ width: "100%" }}>
                      <MKTypography
                        sx={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          fontFamily: "atomic-regular",
                          letterSpacing: "0.15em",
                          color: "#ffffff",
                          marginTop: "10px",
                          transform: "rotate(-2deg)",
                          fontSize: {
                            xs: "1rem", // Móvil
                            sm: "1rem", // Tablet pequeña
                            md: "1.5rem", // Tablets y laptops pequeñas
                            lg: "2rem", // Laptops grandes (1200px)
                            xl: "2.5rem", // Monitores grandes (1536px)
                          },
                          "@media (min-width: 1200px) and (max-width: 1800px)":
                            {
                              fontSize: "2.25rem", // Tamaño entre 1200px y 1800px
                            },
                          animation: "blink-animation2 0.2s 3 1s",
                          textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                        }}
                        variant="h1"
                        align="center"
                        gutterBottom
                      >
                        Renault
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box mt={-2}>
                        <MKTypography
                          sx={{
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#FFD415",
                            marginTop: "-10px",
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "2.5rem", // Móvil
                              sm: "2.5rem",
                              md: "2.5rem",
                              lg: "2.5rem", // Más grande para 1200px
                              xl: "4.5rem", // Tamaño para 1536px en adelante
                            },
                            "@media (min-width: 1200px) and (max-width: 1800px)":
                              {
                                fontSize: "3rem", // Ajuste fino entre 1200px y 1800px
                              },
                            animation: "blink-animation2 0.2s 3 1s",
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          Preference
                        </MKTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box mt={-4}>
                        <MKTypography
                          sx={{
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#FFFFFF",
                            marginTop: "-10px", // Ajusta la separación superior según sea necesario
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "0.7rem", // Móvil
                              sm: "0.7rem",
                              md: "0.7rem",
                              lg: "1rem", // Más grande para 1200px
                              xl: "1.5rem", // Tamaño para 1536px en adelante
                            },
                            "@media (min-width: 1200px) and (max-width: 1800px)":
                              {
                                fontSize: "1rem", // Ajuste fino entre 1200px y 1800px
                              },
                            animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          La solución
                        </MKTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={12} p={1}>
                      <Box mt={-0}>
                        <MKTypography
                          variant="body2"
                          sx={{ color: "#FFFFFF", textAlign: "justify" }}
                        >
                          Descubre{" "}
                          <a
                            style={{ color: "#FFD415" }}
                            href="https://www.renault.es/renault-financiacion/renault-preference.html"
                          >
                            Renault Preference
                          </a>
                          , una solución de{" "}
                          <a
                            style={{ color: "#FFD415" }}
                            href="https://mobilize-fs.es/"
                          >
                            MFS (Mobilize Financial Services){" "}
                          </a>
                          , que te permite cambiar de coche cada 3, 4 o 5 años.
                          <br />
                          <br />
                          Disfruta de una financiación adaptada a ti y elige el{" "}
                          <a
                            style={{ color: "#FFD415" }}
                            href="https://www.renault.es/"
                          >
                            Renault{" "}
                          </a>{" "}
                          que más te guste.{" "}
                          <strong>¡Fácil, flexible y a tu medida!</strong>
                        </MKTypography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Container>
          <MKBox mt={4}>
            <MKTypography
              sx={{
                //fontFamily: "Rodina-Regular",
                //letterSpacing: "0.15em",
                color: "#FFFFFF",
                //transform: "rotate(-2deg)",
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "3rem",
                  lg: "4rem",
                  xl: "3rem",
                },
                animation: "blink-animation 0.2s 3 1s", // Agrega la animación
              }}
              variant="h6"
              align="center"
              gutterBottom
            >
              VERSATILIDAD
            </MKTypography>
          </MKBox>
          <MKBox mt={-2}>
            <MKTypography
              sx={{
                color: "#FFFFFF",
              }}
              variant="h5"
              align="center"
              gutterBottom
            >
              Al finalizar tu contrato tienes 3 opciones para elegir
            </MKTypography>
          </MKBox>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 6,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards2.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    ></div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Box sx={{ marginTop: "10px" }}>
          <Box
            mt={4}
            sx={{
              minHeight: "69vh",
              width: "100%",
              backgroundImage: `url(${bgRenault2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: `${appBarHeight}px`,
              position: "relative", // Necesario para el pseudo-elemento
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 10%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 20%)
      `,
              },
            }}
          >
            {/* El contenido dentro de la caja */}
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Grid container sx={{ width: "80%" }} spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Box sx={{ width: "100%" }}>
                    <Grid container sx={{ width: "100%" }} spacing={2}>
                      <Grid p={0} item xs={12} lg={12} sx={{ width: "100%" }}>
                        <MKTypography
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#ffffff",
                            marginTop: "10px",
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "1rem", // Móvil
                              sm: "1rem", // Tablet pequeña
                              md: "1.5rem", // Tablets y laptops pequeñas
                              lg: "2rem", // Laptops grandes (1200px)
                              xl: "2.5rem", // Monitores grandes (1536px)
                            },
                            "@media (min-width: 1200px) and (max-width: 1800px)":
                              {
                                fontSize: "2.25rem", // Tamaño entre 1200px y 1800px
                              },
                            animation: "blink-animation2 0.2s 3 1s",
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          Renault
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Box mt={-2}>
                          <MKTypography
                            sx={{
                              fontFamily: "atomic-regular",
                              letterSpacing: "0.15em",
                              color: "#FFD415",
                              marginTop: "-10px",
                              transform: "rotate(-2deg)",
                              fontSize: {
                                xs: "2.5rem", // Móvil
                                sm: "2.5rem",
                                md: "2.5rem",
                                lg: "2.5rem", // Más grande para 1200px
                                xl: "4.5rem", // Tamaño para 1536px en adelante
                              },
                              "@media (min-width: 1200px) and (max-width: 1800px)":
                                {
                                  fontSize: "3rem", // Ajuste fino entre 1200px y 1800px
                                },
                              animation: "blink-animation2 0.2s 3 1s",
                              textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                            }}
                            variant="h1"
                            align="center"
                            gutterBottom
                          >
                            Preference
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Box mt={-4}>
                          <MKTypography
                            sx={{
                              fontFamily: "atomic-regular",
                              letterSpacing: "0.15em",
                              color: "#FFFFFF",
                              marginTop: "-10px", // Ajusta la separación superior según sea necesario
                              transform: "rotate(-2deg)",
                              fontSize: {
                                xs: "0.7rem", // Móvil
                                sm: "0.7rem",
                                md: "0.7rem",
                                lg: "1rem", // Más grande para 1200px
                                xl: "1.5rem", // Tamaño para 1536px en adelante
                              },
                              "@media (min-width: 1200px) and (max-width: 1800px)":
                                {
                                  fontSize: "1rem", // Ajuste fino entre 1200px y 1800px
                                },
                              animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                              textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                            }}
                            variant="h1"
                            align="center"
                            gutterBottom
                          >
                            La solución
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12} p={1}>
                        <Box mt={-0}>
                          <MKTypography
                            variant="body2"
                            sx={{ color: "#FFFFFF", textAlign: "justify" }}
                          >
                            Descubre{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renault.es/renault-financiacion/renault-preference.html"
                            >
                              Renault Preference
                            </a>
                            , una solución diseñada por{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://mobilize-fs.es/"
                            >
                              MFS (Mobilize Financial Services){" "}
                            </a>
                            la entidad financiera de{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renaultgroup.com/en/"
                            >
                              Renault Group
                            </a>
                            , que te permite cambiar de coche cada 3, 4 o 5
                            años.
                            <br />
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renault.es/renault-financiacion/renault-preference.html"
                            >
                              Renault Preference
                            </a>{" "}
                            te ofrece unas condiciones de financiación
                            totalmente adaptadas a tus necesidades.{" "}
                            <strong>¡Es la opción perfecta!</strong>. Es un
                            producto financiero muy sencillo: elige el{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renault.es/"
                            >
                              Renault{" "}
                            </a>
                            que más te guste y escoge la duración del contrato:
                            3, 4 o 5 años (1)
                          </MKTypography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}></Grid>
                <Grid item xs={12} lg={4} p={0}>
                  {
                    <Carousel
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  }
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Container>
          <MKBox mt={15}>
            <MKTypography
              sx={{
                //fontFamily: "Rodina-Regular",
                //letterSpacing: "0.15em",
                color: "#FFFFFF",
                //transform: "rotate(-2deg)",
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "3rem",
                  lg: "4rem",
                  xl: "3rem",
                },
                animation: "blink-animation 0.2s 3 1s", // Agrega la animación
              }}
              variant="h6"
              align="center"
              gutterBottom
            >
              COMODIDAD
            </MKTypography>
          </MKBox>
          <MKBox mt={-2}>
            <MKTypography
              sx={{
                color: "#FFFFFF",
              }}
              variant="h5"
              align="center"
              gutterBottom
            >
              Servicios opcionales que Kode Cars Market siempre incluye en la
              cuota
            </MKTypography>
          </MKBox>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 6,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards3.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    ></div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container>
          <MKBox mt={15}>
            <MKTypography
              sx={{
                //fontFamily: "Rodina-Regular",
                //letterSpacing: "0.15em",
                color: "#FFFFFF",
                //transform: "rotate(-2deg)",
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "3rem",
                  lg: "4rem",
                  xl: "3rem",
                },
                animation: "blink-animation 0.2s 3 1s", // Agrega la animación
              }}
              variant="h6"
              align="center"
              gutterBottom
            >
              NUESTRA RECOMENDACIÓN
            </MKTypography>
          </MKBox>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <MKTypography
              variant="body2"
              sx={{ color: "#FFFFFF", textAlign: "center" }}
            >
              En Kode Cars Market, recomendamos{" "}
              <strong>Renault Preference a 37 meses</strong> como la opción más
              económica.
            </MKTypography>
          </Box>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 6,
            },
            mb: 8,
          }}
        >
          <MKBox
        sx={{
          position: "absolute",
          top:  0,
          left: 0,
          height: "100%",
          backgroundImage: `url(${bgImagemanch})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          zIndex: 0,
          transform: "rotate(-14deg)",
          overflow: "hidden",
          opacity: "30%",
        }}
      />
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              display: "flex",
              //justifyContent: "center",
              //alignItems: "left",
            }}
          >
            <Grid
              sx={{
                width: "100%",
                minHeight: "20rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={6}
              p={2}
            >
              <Box
                p={2}
                sx={{
                  textAlign: "center",
                  py: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  position: "relative", // Esto asegura que el número grande se posicione relativo a cada slide
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <MKTypography
                    color="white"
                    display="block"
                    variant="h5"
                    fontWeight="bold"
                  >
                    Cuota más baja
                  </MKTypography>
                  <MKTypography
                    variant="body2"
                    color="white"
                    fontWeight="regular"
                    sx={{ mt: 2 }}
                  >
                    El valor residual asignado por Renault a 3 años hace que las
                    cuotas sean más económicas que a 4 o 5 años.
                  </MKTypography>
                </Box>

                {/* Número grande en el fondo */}
                <MKTypography
                  fontWeight="bold"
                  sx={{
                    fontSize: "20rem",
                    opacity: "0.1",
                    color: "#ffffff",
                    position: "absolute", // Posición absoluta para que esté detrás del contenido
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)", // Centrar el número
                    zIndex: 0, // Asegura que el número esté en el fondo
                  }}
                >
                  1
                </MKTypography>
              </Box>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                minHeight: "20rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={6}
              p={2}
            >
              <Box
                p={2}
                sx={{
                  textAlign: "center",
                  py: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  position: "relative", // Esto asegura que el número grande se posicione relativo a cada slide
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <MKTypography
                    color="white"
                    display="block"
                    variant="h5"
                    fontWeight="bold"
                  >
                    Beneficios por fidelidad
                  </MKTypography>
                  <MKTypography
                    variant="body2"
                    color="white"
                    fontWeight="regular"
                    sx={{ mt: 2 }}
                  >
                    Al cambiar tu coche cada 3 años, Renault elimina comisiones
                    (-3,1%) y ofrece un descuento adicional (+2%), lo que se
                    traduce en un <strong>5,1% de ahorro</strong> respecto a tu
                    primer coche.
                  </MKTypography>
                </Box>

                {/* Número grande en el fondo */}
                <MKTypography
                  fontWeight="bold"
                  sx={{
                    fontSize: "20rem",
                    opacity: "0.1",
                    color: "#ffffff",
                    position: "absolute", // Posición absoluta para que esté detrás del contenido
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)", // Centrar el número
                    zIndex: 0, // Asegura que el número esté en el fondo
                  }}
                >
                  2
                </MKTypography>
              </Box>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                minHeight: "20rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={6}
              p={2}
            >
              <Box
                p={2}
                sx={{
                  textAlign: "center",
                  py: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  position: "relative", // Esto asegura que el número grande se posicione relativo a cada slide
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <MKTypography
                    color="white"
                    display="block"
                    variant="h5"
                    fontWeight="bold"
                  >
                    Mejores condiciones
                  </MKTypography>
                  <MKTypography
                    variant="body2"
                    color="white"
                    fontWeight="regular"
                    sx={{ mt: 2 }}
                  >
                    Cuanto antes cambies de coche, antes disfrutarás de
                    condiciones más ventajosas, con una cuota mensual más baja.
                  </MKTypography>
                </Box>

                {/* Número grande en el fondo */}
                <MKTypography
                  fontWeight="bold"
                  sx={{
                    fontSize: "20rem",
                    opacity: "0.1",
                    color: "#ffffff",
                    position: "absolute", // Posición absoluta para que esté detrás del contenido
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)", // Centrar el número
                    zIndex: 0, // Asegura que el número esté en el fondo
                  }}
                >
                  3
                </MKTypography>
              </Box>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                minHeight: "20rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={6}
              p={2}
            >
              <Box
                p={2}
                sx={{
                  textAlign: "center",
                  py: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  position: "relative", // Esto asegura que el número grande se posicione relativo a cada slide

                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <MKTypography
                    color="white"
                    display="block"
                    variant="h5"
                    fontWeight="bold"
                  >
                    Garantía completa
                  </MKTypography>
                  <MKTypography
                    variant="body2"
                    color="white"
                    fontWeight="regular"
                    sx={{ mt: 2 }}
                  >
                    Disfrutarás de garantía total durante los 3 años, algo que
                    no ocurre con contratos más largos sin coste adicional.
                  </MKTypography>
                </Box>

                {/* Número grande en el fondo */}
                <MKTypography
                  fontWeight="bold"
                  sx={{
                    fontSize: "20rem",
                    opacity: "0.1",
                    color: "#ffffff",
                    position: "absolute", // Posición absoluta para que esté detrás del contenido
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)", // Centrar el número
                    zIndex: 0, // Asegura que el número esté en el fondo
                  }}
                >
                  4
                </MKTypography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <MKTypography
              variant="body2"
              sx={{ color: "#FFFFFF", textAlign: "center" }}
            >
              Si prefieres quedarte con el coche, la opción de{" "}
              <strong>61 meses</strong> es más económica a largo plazo debido al
              menor valor residual.
              <br></br>
              <br></br>
              <strong>Conclusión:</strong> Si prefieres cambiar de coche cada 3
              años, la opción de 37 meses es ideal. Si planeas quedarte con el
              coche, el contrato a 5 años es la mejor elección.
              <br></br>
              <br></br>
              Se pueden presupuestar diferentes modalidades de seguro: desde un
              Todo Riesgo 100% para menores de 31 años, hasta un Todo Riesgo con
              franquicia de 600€ para mayores de 31 años. En todos los casos,
              reparando en la Red Oficial Renault, tendrás una reducción del 50%
              en la franquicia contratada.
            </MKTypography>
          </Box>
        </Container>

        <Container
          sx={{
            mt: {
              xs: 10,
              sm: 10,
              md: 10,
              lg: 10,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Card
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.07)",
              padding: "20px",
              width: "100%",
              borderRadius: "8px",
            }}
          >
            <MKBox
              sx={{
                justifyContent: "center",
                display: "flex",
                position: "relative",
                flexDirection: "column",
              }}
            >
              <MKTypography
                variant="h4"
                sx={{ color: "#a8a8a8", textAlign: "center" }}
              >
                ¡MUY IMPORTANTE!
              </MKTypography>
              <MKTypography
                variant="h6"
                sx={{
                  marginTop: "10px",
                  color: "#a8a8a8",
                  textAlign: "center",
                }}
              >
                La edad y la antigüedad del carnet no importan. Esto hace que
                Renault Preference sea una opción ideal y muy económica para
                conductores noveles, menores de 25 años y con menos de 2 años de
                carnet.
              </MKTypography>
            </MKBox>
          </Card>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 10,
              sm: 10,
              md: 10,
              lg: 10,
            },
            mb: 18,
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <MKTypography
              variant="body2"
              sx={{ color: "#FFFFFF", textAlign: "center" }}
            >
              Descubre nuestra oferta exclusiva para nuevos conductores recién
              titulados.
              <strong>KCM - AFTER SCHOOL</strong> te espera. No arriesgues tu
              seguridad con coches de segunda mano. Elige un Renault nuevo y
              aprende a conducir con confianza. ¡Accede ahora y conduce seguro
              desde el primer día!
            </MKTypography>
          </Box>
        </Container>

        <Box sx={{ marginTop: "70px" }}>
          <Box
            mt={8}
            sx={{
              minHeight: "69vh",
              width: "100%",
              backgroundImage: `url(${backets})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: `${appBarHeight}px`,
              position: "relative", // Necesario para el pseudo-elemento
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 10%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 20%)
      `,
              },
            }}
          >
            {/* El contenido dentro de la caja */}
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Grid container sx={{ width: "80%" }} spacing={2}>
                <Grid item xs={12} lg={8}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        width: "80%",
                        display: "flex",
                        //justifyContent: "center",
                        //alignItems: "left",
                      }}
                    >
                      <Grid
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        item
                        xs={12}
                        lg={4}
                        p={2}
                      >
                        <Card
                          style={{
                            backgroundColor: "#181818",
                            boxShadow:
                              "0px 10px 42px 10px rgba(73, 245, 116,0.1)",
                            borderRadius: "16px",
                            padding: "24px",
                            overflow: "hidden",
                            maxWidth: "400px", // Limitar el ancho para mejorar la legibilidad
                            margin: "auto", // Centramos la tarjeta en la página
                          }}
                        >
                          {/* Contenedor del Título */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mb={3}
                          >
                            {/* Título Principal */}
                            <Typography
                              variant="h3" // Aumentamos el tamaño del título
                              style={{
                                color: "#FFD415",
                                textTransform: "capitalize",
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              Todo Riesgo
                            </Typography>

                            {/* Subtítulo */}
                            <Typography
                              variant="h5" // Aumentamos el tamaño del subtítulo
                              style={{
                                color: "#FFFFFF",
                                textTransform: "capitalize",
                                fontWeight: "bold",
                                opacity: 0.8, // Añadimos opacidad para diferenciarlo
                                marginBottom: "16px",
                              }}
                            >
                              Franquicia de 600€ (ó 300€)
                            </Typography>
                            {/* Divider decorativo */}
                            <Divider
                              style={{
                                width: "60%",
                                backgroundColor: "#FFD415",
                                height: "2px", // Grosor del divider para destacarlo
                              }}
                            />
                          </Box>
                          {/* Contenedor de descripción */}
                          <Box mt={0} mb={2}>
                            <Typography
                              variant="body2"
                              style={{
                                color: "#ffffff",
                                textAlign: "justify",
                                marginTop: "12px",
                              }}
                            >
                              Aquí puedes añadir una breve descripción del
                              seguro, destacando las ventajas y características
                              principales para que el cliente tenga claro qué
                              incluye esta opción de seguro.
                            </Typography>
                          </Box>
                          {/* Divider para separar la descripción del precio */}
                          <Divider
                            style={{ backgroundColor: "#ffffff", opacity: 0.2 }}
                          />
                          {/* Contenedor del Precio */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mt={3}
                          >
                            <Typography
                              variant="h4"
                              style={{
                                color: "#FFD415",
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              49.99€/mes
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                color: "#ffffff",
                                textTransform: "uppercase",
                                opacity: 0.7,
                              }}
                            >
                              Precio estimado
                            </Typography>
                          </Box>
                        </Card>
                      </Grid>
                      <Grid item sx={{ width: "100%" }} xs={12} lg={4} p={2}>
                        <Card
                          style={{
                            backgroundColor: "#181818",
                            boxShadow:
                              "0px 10px 42px 10px rgba(73, 245, 116,0.1)",
                            borderRadius: "16px",
                            padding: "24px",
                            overflow: "hidden",
                            maxWidth: "400px", // Limitar el ancho para mejorar la legibilidad
                            margin: "auto", // Centramos la tarjeta en la página
                          }}
                        >
                          {/* Contenedor del Título */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mb={3}
                          >
                            {/* Título Principal */}
                            <Typography
                              variant="h3" // Aumentamos el tamaño del título
                              style={{
                                color: "#FFD415",
                                textTransform: "capitalize",
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              Todo Riesgo
                            </Typography>

                            {/* Subtítulo */}
                            <Typography
                              variant="h5" // Aumentamos el tamaño del subtítulo
                              style={{
                                color: "#FFFFFF",
                                textTransform: "capitalize",
                                fontWeight: "bold",
                                opacity: 0.8, // Añadimos opacidad para diferenciarlo
                                marginBottom: "16px",
                              }}
                            >
                              Franquicia de 600€ (ó 300€)
                            </Typography>
                            {/* Divider decorativo */}
                            <Divider
                              style={{
                                width: "60%",
                                backgroundColor: "#FFD415",
                                height: "2px", // Grosor del divider para destacarlo
                              }}
                            />
                          </Box>
                          {/* Contenedor de descripción */}
                          <Box mt={0} mb={2}>
                            <Typography
                              variant="body2"
                              style={{
                                color: "#ffffff",
                                textAlign: "justify",
                                marginTop: "12px",
                              }}
                            >
                              Aquí puedes añadir una breve descripción del
                              seguro, destacando las ventajas y características
                              principales para que el cliente tenga claro qué
                              incluye esta opción de seguro.
                            </Typography>
                          </Box>
                          {/* Divider para separar la descripción del precio */}
                          <Divider
                            style={{ backgroundColor: "#ffffff", opacity: 0.2 }}
                          />
                          {/* Contenedor del Precio */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mt={3}
                          >
                            <Typography
                              variant="h4"
                              style={{
                                color: "#FFD415",
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              49.99€/mes
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                color: "#ffffff",
                                textTransform: "uppercase",
                                opacity: 0.7,
                              }}
                            >
                              Precio estimado
                            </Typography>
                          </Box>
                        </Card>
                      </Grid>
                      <Grid item sx={{ width: "100%" }} xs={12} lg={4} p={2}>
                        <Card
                          style={{
                            backgroundColor: "#181818",
                            boxShadow:
                              "0px 10px 42px 10px rgba(73, 245, 116,0.1)",
                            borderRadius: "16px",
                            padding: "24px",
                            overflow: "hidden",
                            maxWidth: "400px", // Limitar el ancho para mejorar la legibilidad
                            margin: "auto", // Centramos la tarjeta en la página
                          }}
                        >
                          {/* Contenedor del Título */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mb={3}
                          >
                            {/* Título Principal */}
                            <Typography
                              variant="h3" // Aumentamos el tamaño del título
                              style={{
                                color: "#FFD415",
                                textTransform: "capitalize",
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              Todo Riesgo
                            </Typography>

                            {/* Subtítulo */}
                            <Typography
                              variant="h5" // Aumentamos el tamaño del subtítulo
                              style={{
                                color: "#FFFFFF",
                                textTransform: "capitalize",
                                fontWeight: "bold",
                                opacity: 0.8, // Añadimos opacidad para diferenciarlo
                                marginBottom: "16px",
                              }}
                            >
                              Franquicia de 600€ (ó 300€)
                            </Typography>
                            {/* Divider decorativo */}
                            <Divider
                              style={{
                                width: "60%",
                                backgroundColor: "#FFD415",
                                height: "2px", // Grosor del divider para destacarlo
                              }}
                            />
                          </Box>
                          {/* Contenedor de descripción */}
                          <Box mt={0} mb={2}>
                            <Typography
                              variant="body2"
                              style={{
                                color: "#ffffff",
                                textAlign: "justify",
                                marginTop: "12px",
                              }}
                            >
                              Aquí puedes añadir una breve descripción del
                              seguro, destacando las ventajas y características
                              principales para que el cliente tenga claro qué
                              incluye esta opción de seguro.
                            </Typography>
                          </Box>
                          {/* Divider para separar la descripción del precio */}
                          <Divider
                            style={{ backgroundColor: "#ffffff", opacity: 0.2 }}
                          />
                          {/* Contenedor del Precio */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mt={3}
                          >
                            <Typography
                              variant="h4"
                              style={{
                                color: "#FFD415",
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              49.99€/mes
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                color: "#ffffff",
                                textTransform: "uppercase",
                                opacity: 0.7,
                              }}
                            >
                              Precio estimado
                            </Typography>
                          </Box>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4} p={0}>
                  <Box sx={{ width: "100%" }}>
                    <Grid container sx={{ width: "100%" }} spacing={2}>
                      <Grid p={0} item xs={12} lg={12} sx={{ width: "100%" }}>
                        <MKTypography
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#ffffff",
                            marginTop: "10px",
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "1rem", // Móvil
                              sm: "1rem", // Tablet pequeña
                              md: "1.5rem", // Tablets y laptops pequeñas
                              lg: "2rem", // Laptops grandes (1200px)
                              xl: "2.5rem", // Monitores grandes (1536px)
                            },
                            "@media (min-width: 1200px) and (max-width: 1800px)":
                              {
                                fontSize: "2.25rem", // Tamaño entre 1200px y 1800px
                              },
                            animation: "blink-animation2 0.2s 3 1s",
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          SEGURO A TODO RIESGO
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Box mt={-2}>
                          <MKTypography
                            sx={{
                              fontFamily: "atomic-regular",
                              letterSpacing: "0.15em",
                              color: "#FFD415",
                              marginTop: "-10px",
                              transform: "rotate(-2deg)",
                              fontSize: {
                                xs: "2.5rem", // Móvil
                                sm: "2.5rem",
                                md: "2.5rem",
                                lg: "2.5rem", // Más grande para 1200px
                                xl: "4.5rem", // Tamaño para 1536px en adelante
                              },
                              "@media (min-width: 1200px) and (max-width: 1800px)":
                                {
                                  fontSize: "3rem", // Ajuste fino entre 1200px y 1800px
                                },
                              animation: "blink-animation2 0.2s 3 1s",
                              textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                            }}
                            variant="h1"
                            align="center"
                            gutterBottom
                          >
                            Preference
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Box mt={-4}>
                          <MKTypography
                            sx={{
                              fontFamily: "atomic-regular",
                              letterSpacing: "0.15em",
                              color: "#FFFFFF",
                              marginTop: "-10px", // Ajusta la separación superior según sea necesario
                              transform: "rotate(-2deg)",
                              fontSize: {
                                xs: "0.7rem", // Móvil
                                sm: "0.7rem",
                                md: "0.7rem",
                                lg: "1rem", // Más grande para 1200px
                                xl: "1.5rem", // Tamaño para 1536px en adelante
                              },
                              "@media (min-width: 1200px) and (max-width: 1800px)":
                                {
                                  fontSize: "1rem", // Ajuste fino entre 1200px y 1800px
                                },
                              animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                              textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                            }}
                            variant="h1"
                            align="center"
                            gutterBottom
                          >
                            VENTAJAS
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12} p={1}>
                        <Box mt={-0}>
                          <MKTypography
                            variant="body2"
                            sx={{ color: "#FFFFFF", textAlign: "justify" }}
                          >
                            Se pueden presupuestar diferentes modalidades de
                            Seguro. Desde un Todo Riesgo 100% para menores de 31
                            años, hasta un Todo Riesgo con Franquicia de 600€
                            para mayores de 31 años. En todos los casos, si se
                            repara el vehículo en cualquiera de los{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renault.es/concesionarios.html"
                            >
                              Talleres Oficiales de la Red Renault
                            </a>
                            , se tendrá derecho a una reducción del 50% de la
                            franquicia contratada.
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12} p={1}>
                        <Box mt={-0}>
                          
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <MKBox bgColor="info" mt={4}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              {<CenteredFooter pt={6} px={1} />}
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default KodePreference;
